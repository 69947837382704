import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import ZmmImg from "/static/products/zmm.png"
import MggImg from "/static/products/mgg.png"
import SupplyChainImg from "/static/products/supply-chain.png"

const products = [
  {
    title: "装满满—智能装箱软件服务",
    desc: "智能装箱软件，一键计算最佳装箱配载规划方案，快速解决估柜、装箱、打包设计等难题",
    link: "zhuangmanman",
    img: ZmmImg,
  },
  {
    title: "码高高—混合码垛规划算法",
    desc: "蓝胖智汇混码算法基于自研时空优化算法引擎可根据订单中不同货品(SKU)信息，实时生成满足不同场景业务要求的稳定垛型",
    link: "magaogao",
    img: MggImg,
  },
  {
    title: "可持续供应链-智能决策解决方案",
    desc: "蓝胖智汇供应链解决方案针对企业供应链流通环节的完整业务流程，基于时间空间多目前优化引擎与多维度大数据洞察",
    link: "supply-chain",
    img: SupplyChainImg,
  },
]

const Products = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const page = params.get("p") || 1
  return (
    <Layout>
      <Seo
        title="装箱配载优化_集装箱配载优化软件-蓝胖智汇"
        keywords="装箱配载优化,集装箱配载优化软件"
        description="胖智汇专注自研装箱配载优化、集装箱配载优化软件，为客户提供仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="../../images/products-banner.png"
          alt="装箱配载优化_集装箱配载优化软件-蓝胖智汇"
          className="w-full"
        />
        <h1 className="absolute top-28 left-24 text-white">产品列表</h1>
      </div>
      <div className="flex gap-6 max-w-screen-xl m-auto mt-24">
        {products.map(p => (
          <div
            className="flex-1 group hover:cursor-pointer"
            key={p.title}
            onClick={() => navigate(p.link)}
          >
            <div>
              <img src={p.img} alt={p.title} />
            </div>
            <div className="p-6 group-hover:bg-[#2d55a8] group-hover:text-white transition-all duration-300">
              <h2 className="text-lg font-bold text-gray-800 group-hover:text-white">
                {p.title}
              </h2>
              <p className="mt-3 text-sm text-gray-500 group-hover:text-white">
                {p.desc}
              </p>
              <p className="mt-6 text-sm text-[#3055a4] group-hover:text-white">
                了解详情 {">"}
              </p>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Products
